import { eStorageKey } from '../enumerators/eStorageKey'
import { decode } from '../utils/token/decode'
import { decodeAndVerify } from '../utils/token/verify'
import { getDate } from '../utils/token/date'
import { TokenTypes } from '../utils/token/type'

import { get, set, remove } from 'local-storage'

import * as CookieNames from '../utils/constants'

type CookieTypes = TokenTypes | 'UserData'

function getCookie(key: CookieTypes) {
  const token = getWithExpiry(key)
  if (key === CookieNames.UserData) return token
  if (token && decodeAndVerify(token)) return token
}

function getWithExpiry(key: string) {
  const itemStr = get<string | null>(key)

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null
  }

  const { value, expiry } = JSON.parse(itemStr)
  const now = new Date()

  if (!expiry) return value

  // compare the expiry time of the item with the current time
  if (now.getTime() > expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    remove(key)
    return null
  }
  return value
}

function setWithExpiry(key: string, value: any, expiry?: Date) {
  set(key, JSON.stringify({ value, expiry: expiry && expiry.getTime() }))
}

function store(value: any, key: CookieTypes, opt: { expires?: Date } = {}) {
  setWithExpiry(key.toString(), value, opt.expires)
}

export const ClearStorage = () => {
  ;[
    CookieNames.RefreshToken,
    CookieNames.AccessToken,
    CookieNames.TemporaryToken,
    CookieNames.UserData
  ].forEach((key) => remove(key))
}

export const ClearTemporary = () => {
  remove(CookieNames.TemporaryToken)
}

export const ClearTokens = () => {
  remove(CookieNames.AccessToken)
  remove(CookieNames.RefreshToken)
}

export const ClearUserData = () => {
  remove(CookieNames.UserData)
}

export const GetAccessToken = () => {
  return getCookie(CookieNames.AccessToken)
}

export const GetRefreshToken = () => {
  return getCookie(CookieNames.RefreshToken)
}

// @deprecated
export const GetStored = (key: any): undefined | any => {
  console.warn(
    'Method GetStored is deprecated. Use GetTemporaryToken, GetAccessToken, GetRefreshToken, or GetUserData'
  )
  return getCookie(eStorageKey[key] || key)
}

export const GetTemporaryToken = () => {
  return getCookie(CookieNames.TemporaryToken)
}

export const GetUserData = () => {
  return getCookie(CookieNames.UserData)
}

// @deprecated
export const Store = (...params: any) => {
  console.warn(
    'Method Store is deprecated. Use StoreTokens, StoreUserData, or StoreTemporaryToken'
  )
  // @ts-ignore
  store(...params)
}

export const StoreTemporaryToken = (token: string) => {
  store(token, CookieNames.TemporaryToken)
}

export const StoreTokens = (token: string, refreshToken: string) => {
  store(token, CookieNames.AccessToken, {
    expires: getDate(decode(token), 'exp')
  })
  store(refreshToken, CookieNames.RefreshToken, {
    expires: getDate(decode(refreshToken), 'exp')
  })
}

export const StoreUserData = (data: any) => {
  store(data, CookieNames.UserData)
}
